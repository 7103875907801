<template>
	<div data-route>
		<div data-element="main">
			<course-view
				:course="course"
			/>
		</div>
	</div>
</template>

<script>

	import CourseView  from '@/components/courses/CourseView';
	import routeParams from '@/mixins/routeParams';
	import api         from '@/services/api';

	export default {
		metaInfo () {
			if (!this.getCourseTitle) {
				return false;
			}
			return {
				title: this.getCourseTitle
			};
		},
		components: {
			CourseView
		},
		mixins: [routeParams],
		data: () => ({
			course: {}
		}),
		computed: {
			getCourseTitle () {
				return this?.course?.title;
			},
			getBreadcrumbs () {
				return [
					{
						path: '/browse-courses',
						text: 'Find a course'
					},
					{
						path: this.getCourseRoute,
						text: this.getCourseTitle
					}
				].filter(Boolean);
			}
		},
		created () {
			this.setCourse();
		},
		methods: {
			async setCourse () {
				const course = await api.courses.getPublishedCourseById({
					courseId: this.$route.params.courseId
				});
				if (!course) {
					this.$store.commit('ui/showError');
					return false;
				}
				this.course = course;
				this.$store.commit('ui/setBreadcrumbs', {
					breadcrumbs: this.getBreadcrumbs
				});
				this.$store.commit('ui/setLoadingIsHidden');
			}
		}
	};

</script>

<style lang="scss" scoped>
	[data-route] {
		[data-element='main'] {
		}
	}
</style>
